<template>
  <main class="home">
    <Header />
    <div class="home-hero__banner" v-if="homeContent">
      <div class="container">
        <transition-group name="fade" tag="div" class="hero-banner__slider">
          <div
            v-for="i in [currentIndex]"
            :key="i"
            class="hero-banner__slider--slide"
          >
            <img :src="currentImg" />
          </div>
        </transition-group>
      </div>
    </div>
    <div class="container">
      <template v-if="content">
        <section
          v-for="page in content.results"
          :class="{
            'home-section': page.uid !== 'projetos',
            'home-section__projects': page.uid === 'projetos'
          }"
          :key="page.id"
          :id="page.uid"
        >
          <template v-if="page.uid !== 'projetos'">
            <div class="home-section__content">
              <h2>{{ $prismic.asText(page.data.title) }}</h2>
              <div v-html="$prismic.asHtml(page.data.description)" />
            </div>
            <img
              :src="page.data.highlight_image.url"
              :alt="page.data.highlight_image.alt"
            />
          </template>
          <template v-else-if="projects">
            <div class="home-section__projects--content">
              <h2>{{ $prismic.asText(page.data.title) }}</h2>
              <div v-html="$prismic.asHtml(page.data.description)" />
            </div>
              <ul class="home-section__projects--list">
                <li
                  v-for="project in projects.results"
                  :key="project.id"
                  @click="openDetails(project.id)"
                >
                  <img
                    :src="project.data.highlight_image.url"
                    :alt="project.data.highlight_image.alt" />

                  <span class="list-item__title">{{ project.data.title }}</span>
                </li>
              </ul>
          </template>
        </section>
      </template>
    </div>
    <div
      v-if="projectDetails"
      class="project-dialog"
    >
      <div class="project-dialog__box">
        <button
          class="project-dialog__close"
          @click="closeDialog"
        >
          <img src="../assets/icon-close.svg" alt="ícone de fechar" />
        </button>
        <div class="project-dialog__title">
          <img
            :src="projectDetails.data.highlight_image.url"
            :alt="projectDetails.data.highlight_image.alt"
          />
          <div class="project-dialog__title--description">
            <p class="project-dialog__title--bold">{{ projectDetails.data.title }}</p>
            <p>{{ projectDetails.data.role }}</p>
          </div>
        </div>
        <div
          class="project-dialog__description"
          v-html="$prismic.asHtml(projectDetails.data.project_description)"
        />
      </div>
    </div>
  </main>
</template>

<script>
import Header from '@/components/Header'
export default {
  components: {
    Header,
  },
  data() {
    return {
      content: null,
      homeContent: null,
      projects: null,
      timer: null,
      currentIndex: 0,
      projectDetails: null
    }
  },
  mounted() {
    this.fetchHomePage()
    this.fetchContent()
    this.fetchProjects()
    this.startSlide()
  },
  computed: {
    currentImg: function() {
      const slider = this.homeContent.data.hero_banner_slider
      return slider[Math.abs(this.currentIndex) % slider.length].image.url;
    }
  },
  methods: {
    async fetchHomePage() {
      const response = await this.$prismic.client.getSingle('home')
      this.homeContent = response
    },
    async fetchContent() {
      const response = await this.$prismic.client.query(
        this.$prismic.Predicates.at('document.type','page'),
        {
          orderings: '[document.last_publication_date]'
        }
      )
      this.content = response

      this.content.results.sort((a, b) => {
        return this.comparePosition(a, b)
      })
    },
    async fetchProjects() {
      const response = await this.$prismic.client.query(
        this.$prismic.Predicates.at('document.type','page_project')
      )
      this.projects = response
    },
    startSlide: function() {
      this.timer = setInterval(this.next, 5000)
    },
    next: function() {
      this.currentIndex += 1
    },
    prev: function() {
      this.currentIndex -= 1
    },
    async openDetails(data) {
      const response = await this.$prismic.client.getByID(data)
      this.projectDetails = response
    },
    closeDialog() {
      this.projectDetails = null
    },
    comparePosition(a, b) {
      if (a.data.position < b.data.position) {
        return -1
      }
      if (a.data.position > b.data.position) {
        return 1
      }
      return 0
    }
  }
}
</script>

<style>
.home {
  background-image: url('../assets/bg-leaf.png');
  background-repeat: no-repeat;
  background-position: -650px 875px;
}

.container {
  margin: 0 auto;
  width: 1208px;
}

.hero-banner__slider {
  height: 638px;
  margin-bottom: 64px;
  position: relative;
  width: 100%;
}

.hero-banner__slider--slide {
  background: #ffffff;
  left: 0;
  height: 100%;
  position: absolute;
  top: 60px;
  width: 100%;
}

.hero-banner__slider img {
  display: block;
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.home-section {
  border-bottom: 1px solid #d6d6d6;
  display: grid;
  gap: 80px;
  grid-template-columns: auto 520px;
  padding: 64px 0;
  text-align: justify;
}

.home-section img {
  width: 100%;
}

.home-section__projects {
  border-bottom: 1px solid #d6d6d6;
  padding: 64px 0 92px;
  text-align: justify;
}

.home-section__projects--content {
  width: calc(100% - 600px);
}

.home-section__projects--list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 360px;
  gap: 120px;
  margin-top: 32px;
  padding: 0;
}

.home-section__projects--list li {
  cursor: pointer;
  letter-spacing: 0.5px;
  list-style: none;
  max-height: 350px;
  position: relative;
  transition: 0.3s;
}

.home-section__projects--list li:hover {
  opacity: 0.8;
}

.home-section__projects--list li img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.home-section__projects--list .list-item__title {
  background-color: #CCEDDD;
  bottom: -42px;
  left: 16px;
  max-width: 220px;
  padding: 36px 24px;
  position: absolute;
}

.home-hero__banner {
  background-color: #EDD2BF;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease;
  overflow: hidden;
  visibility: visible;
  position: absolute;
  width: 100%;
  opacity: 1;
}

.fade-enter,
.fade-leave-to {
  visibility: hidden;
  width: 100%;
  opacity: 0;
}

.project-dialog {
  align-items: center;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  left: 0;
  justify-content: center;
  height: 100vh;
  position: fixed;
  top: 0;
  width: 100%;
}

.project-dialog__box {
  background-color: #ffffff;
  border-radius: 20px;
  max-height: 70vh;
  max-width: 1280px;
  padding: 142px 64px 64px;
  position: relative;
  width: 80%;
}

.project-dialog__close {
  background: none;
  border: none;
  position: absolute;
  right: 32px;
  top: 32px;
}

.project-dialog__title {
  align-items: center;
  background: #EDD2BF;
  display: grid;
  gap: 32px;
  grid-template-columns: 650px auto;
  grid-template-rows: 298px;
  margin: 0 -64px;
  padding-left: 64px;
}

.project-dialog__title img {
  display: block;
  margin: -24px 0;
  width: 100%;
}

.project-dialog__title--bold {
  font-weight: 700;
}

.project-dialog__title--description p {
  font-size: 14px;
}

.project-dialog__description {
  font-size: 14px;
  margin-top: 80px;
  max-height: 30vh;
  overflow: auto;
}

@media (max-width: 1280px) {
  .container {
    width: 80%;
  }

  .hero-banner__slider {
    height: 438px;
  }

  .home-section {
    grid-template-columns: 100%;
  }

  .home-section__projects--content {
    width: 100%;
  }

  .home-section__projects--list {
    grid-template-columns: 100%;
  }

  .project-dialog__box {
    padding: 80px 32px 32px;
  }

  .project-dialog__title {
    margin: 0 -32px;
    gap: 12px;
    grid-template-columns: 50% auto;
    grid-template-rows: 220px;
    padding-left: 22px;
  }
}
</style>