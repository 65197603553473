import Vue from 'vue'
import App from './App.vue'
import router from './router'
import PrismicVue from '@prismicio/vue'

Vue.config.productionTip = false

const accessToken = ''
const endpoint = 'https://thassiamoro.cdn.prismic.io/api/v2'

Vue.use(PrismicVue, {
  endpoint,
  apiOptions: { accessToken }
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
