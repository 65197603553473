<template>
  <header class="header">
    <div
      v-if="response.data"
      class="header-title"
    >
      <h1>{{ $prismic.asText(response.data.title) }}</h1>
      <h2>{{ $prismic.asText(response.data.subtitle) }}</h2>
    </div>
    <nav
      v-if="response.data"
      class="header-navigation"
    >
      <ul class="header-navigation__menu">
        <li v-for="item in response.data.menu" :key="item.menu_item">
          <a :href="`#${item.menu_item}`">
            {{ item.menu_item }}
          </a>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
export default {
  data() {
    return {
      response: {}
    }
  },
  mounted() {
    this.getContent()
  },
  methods: {
    async getContent() {
      const response = await this.$prismic.client.getSingle('header')
      this.response = response
    }
  }
}
</script>

<style>
.header {
  align-items: center;
  background: #ffffff;
  display: grid;
  gap: 64px;
  grid-template-columns: 118px auto;
  margin: 0 auto;
  padding: 20px 0;
  width: 1208px;
}

.header-title {
  color: #363636;
}

.header-title h1,
.header-title h2 {
  font-size: 18px;
  font-weight: 200;
  text-align: left;
  text-transform: uppercase;
}

.header-title h2 {
  border-top: 4px solid;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.5px;
  padding-top: 18px;
}

.header-navigation__menu {
  padding: 0;
}

.header-navigation__menu li {
  list-style: none;
  margin: 10px 0;
}

.header-navigation__menu li a {
  color: #363636;
  position: relative;
  text-decoration: none;
}

.header-navigation__menu li a::after {
  background: #EDD2BF;
  bottom: 0;
  content: '';
  height: 8px;
  left: 0;
  position: absolute;
  width: 0;
  z-index: -1;
  transition: .5s;
}

.header-navigation__menu li a:hover::after {
  width: 24px;
}

@media (max-width: 1280px) {
  .header {
    width: 80%;
  }
}
</style>